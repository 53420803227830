// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/logo_white.webp", import.meta.url);
// Module
var code = `<template>
    <require from="./header-standard.scss"></require>
    <require from="../loader-ring/loader-ring"></require>
    <require from="../languages/languages"></require>

    <section id="header-standard">
        <img id="back" if.bind="showBackButton" click.delegate="router.navigateBack()" src="${___HTML_LOADER_IMPORT_0___}">
        <a id="mybankabrand" if.bind="showLogo" class="mybankabrand flex-row" href="/welcome" router-ignore>
          <img src="${___HTML_LOADER_IMPORT_1___}">
          <span>\${welcome_title}</span>
        </a>
        <div class="flex-grow"></div>
        <h1 if.bind="showMessage" class="message">\${message}</h1>
        <languages if.bind="showLanguage"></languages>
        
    </section>
</template>
  `;
// Exports
export default code;